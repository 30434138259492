@import "../../styles/shared.scss";

.logo{
  text-align: left;
  width: 100%;
  margin-bottom: 2em;
}

.menu-wrapper{
  display: flex;
  flex-direction: column;
  justify-content: space-between;
  height: 100%;

  a {
    color: $color-secondary-2;
    text-decoration: none;
    cursor: pointer;
    width: 100%;
    padding: 10px 0 10px 15px;
    display: block;

    &:hover{
      color: $color-secondary-1;
    }

    &.active{
      border-radius: 15px;
      background-color: $color-secondary-5;
      color: $color-principal;
      font-weight: 500;
    }
  }
}

.menu-items {
  p{ margin: 0; }

  a {
    color: $color-secondary-2;
    text-decoration: none;
    cursor: pointer;
    width: 100%;
    padding: 10px 0 10px 15px;
    display: block;

    &:hover{
      color: $color-secondary-1;
    }

    &.active{
      border-radius: 15px;
      background-color: $color-secondary-5;
      color: $color-principal;
      font-weight: 500;
    }
  }
}