@import "../../styles/shared.scss";

.select-container {
  display: flex;
  flex-direction: column;
  position: relative;

  &__select-caret {
    position: absolute;
    right: 1em;
    top: 0;
    bottom: 0;
    margin-top: auto;
    margin-bottom: auto;
    z-index:0;
  }
}


.select-container__select {
  cursor: pointer;
  transition: border-color 0.3s ease;
  border-radius: 32px;
  outline: none;
  border: 1px solid $color-border-input;
  background-color: transparent !important;
  margin: 8px 0;
  padding: 17px 24px;
  width: 100%;
  font-size: 14px;
  line-height: 20px;
  font-family: $inter-tight;
  color: $color-secondary-2;
  letter-spacing: 0.5px;
  z-index: 99;
  appearance: none;
  ::placeholder{
    color: $color-secondary-3;
  }


  &.error {
    border-color: red;
  }

  &.active {
    border-color: $color-principal;
    color: $color-secondary-3;
  }
}
