@import "../../styles/shared.scss";

.btn{
  font-size: 16px;
  align-items: center;
  cursor: pointer !important;

  &.btn-white {
    display: flex;
    align-items: center;
    justify-content: center;
    text-align: center;
    width: 100%;
    border-radius: 32px;
    border: 0.5px solid $color-secondary-1;
    background-color: transparent;
    padding: 12px 24px 12px 24px;
    font-family: $inter-tight;

    .icon,
    svg {
      width: 16px;
      height: 16px;
    }

    &.big {
      align-items: center;
      padding: 16px 20px 16px 20px;

      .icon,
      svg {
        width: 20px;
        height: 20px;
      }
    }

    &:hover {
      background-color: $color-secondary-1;
      border: 0.5px solid $color-secondary-1;
      color: $color-secondary-7;

      .btn-icon {
        fill: $color-secondary-7;

        path {
          fill: $color-secondary-7;
        }
      }
    }

    .btn-icon {
      fill: $color-secondary-1;
      display: inline-block;

      &.left {
        margin-right: 8px;
      }
      &.right {
        margin-left: 8px;
      }

      path {
        fill: $color-secondary-1;
      }
    }
  }

  @media (min-width: $breakpoint-tablet) {
    &.btn-white {
      width: auto;
    }
  }

  &.btn-white-transparent {
    display: flex;
    align-items: center;
    justify-content: center;
    text-align: center;
    width: 100%;
    border-radius: 32px;
    border: 0.5px solid $color-secondary-7;
    background-color: transparent;
    color: $color-secondary-7;
    padding: 12px 24px 12px 24px;

    .icon,
    svg {
      width: 16px;
      height: 16px;
    }

    &.big {
      align-items: center;
      padding: 16px 20px 16px 20px;

      .icon,
      svg {
        width: 20px;
        height: 20px;
      }
    }

    &:hover {
      background-color: $color-secondary-7;
      border: 0.5px solid $color-secondary-7;
      color: $color-secondary-1;

      .btn-icon {
        fill: $color-secondary-1;

        path {
          fill: $color-secondary-1;
        }
      }
    }

    .btn-icon {
      fill: $color-secondary-7;
      display: inline-block;

      &.left {
        margin-right: 8px;
      }
      &.right {
        margin-left: 8px;
      }

      path {
        fill: $color-secondary-7;
      }
    }
  }

  @media (min-width: $breakpoint-tablet) {
    &.btn-white-transparent {
      width: auto;
    }
  }

  &.btn-orange {
    display: flex;
    align-items: center;
    justify-content: center;
    text-align: center;
    width: 100%;
    border-radius: 32px;
    border: 0.5px solid $color-principal;
    background-color: transparent;
    color: $color-principal;
    padding: 12px 24px 12px 24px;

    .icon,
    svg {
      width: 16px;
      height: 16px;
    }

    &.big {
      align-items: center;
      padding: 16px 20px 16px 20px;

      .icon,
      svg {
        width: 20px;
        height: 20px;
      }
    }

    &:hover {
      background-color: $color-principal;
      border: 0.5px solid $color-principal;
      color: $color-secondary-7;

      .btn-icon {
        fill: $color-secondary-7;

        path {
          fill: $color-secondary-7;
        }
      }
    }

    .btn-icon {
      fill: $color-principal;

      &.left {
        margin-right: 8px;
      }
      &.right {
        margin-left: 8px;
      }

      path {
        fill: $color-principal;
      }
    }
  }

  @media (min-width: $breakpoint-tablet) {
    &.btn-orange {
      width: auto;
    }
  }

  &.btn-orangebg {
    display: flex;
    align-items: center;
    justify-content: center;
    text-align: center;
    width: 100%;
    border-radius: 32px;
    border: 0.5px solid $color-principal;
    background-color: $color-principal;
    color: $color-white;
    padding: 12px 24px 12px 24px;

    .icon,
    svg {
      width: 16px;
      height: 16px;
    }

    &.big {
      align-items: center;
      padding: 16px 20px 16px 20px;

      .icon,
      svg {
        width: 20px;
        height: 20px;
      }
    }

    &:hover {
      background-color: $color-principal;
      border: 0.5px solid $color-principal;
      color: $color-secondary-7;

      .btn-icon {
        fill: $color-secondary-7;

        path {
          fill: $color-secondary-7;
        }
      }
    }

    .btn-icon {
      fill: $color-principal;

      &.left {
        margin-right: 8px;
      }
      &.right {
        margin-left: 8px;
      }

      path {
        fill: $color-principal;
      }
    }
  }

  @media (min-width: $breakpoint-tablet) {
    &.btn-orangebg {
      width: auto;
    }
  }
}