@import "./../../styles/shared.scss";

.icon {
  display: inline-block;
  vertical-align: middle;
  cursor: pointer;

  svg {
    display: block;
  }

  &.disabled {
    opacity: 0.3;
  }

  &--xsmall,
  &--xsmall svg {
    width: 10px;
    height: 10px;
  }

  &--small,
  &--small svg {
    width: 16px;
    height: 16px;
  }

  &--medium,
  &--medium svg {
    width: 24px;
    height: 24px;
  }

  &--large,
  &--large svg {
    height: 40px;
  }

  &--xlarge,
  &--xlarge svg {
    width: 72px;
    height: 72px;
  }

  &--background-1 {
    background-color: $color-secondary-7;
    padding: 5px;
    border: 1px solid rgba(181, 179, 179, 0.83);
    border-radius: 50px;
  }

  &--background-2 {
    background-color: rgba(181, 179, 179, 0.83);
    padding: 5px;
    border-radius: 50%;
  }

  &--background-3 {
    background-color: $color-secondary-7;
    padding: 5px;
    border: 2px solid rgba(181, 179, 179, 0.83);
    border-radius: 50%;
  }

  &--background-4 {
    background-color: $color-secondary-1;
    border-radius: 50px;
    svg {
      transform: scale(0.6); 
    }
  }

  &--background-5 {
    background-color: $color-principal;
    border-radius: 50px;
    svg {
      transform: scale(0.6); 
    }
  }

  &--background-6 {
    background-color: $color-secondary-7;
    border-radius: 50%;
    svg {
      transform: scale(0.8); 
    }
  }

  &--border-1 {
    &:hover {
      background-color: $color-secondary-7;
      border: 0.5px solid rgba(181, 179, 179, 0.83);
      border-radius: 50%;
      box-shadow: 0px 0px 6px $color-tertiary-6;
    }
  }

  &--border-2 {
    background-color: rgba(255, 255, 255, 0.373);
    border: 1px solid rgba(140, 140, 140, 0.83);
    border-radius: 50px;
    svg {
      transform: scale(0.8); 
    }
  }

  &--color-principal {
    fill: $color-principal;
    stroke: $color-principal;

    path {
      fill: $color-principal;
      stroke: transparent;
    }
  }

  &--color-principal-border {
    fill: $color-principal;
    background-color: $color-secondary-7;
    border: 1px solid $color-principal;
    border-radius: 50%;

    path {
      fill: $color-principal;
    }
  }

  &--color-principal-border-transparent {
    fill: $color-principal;
    background-color: transparent;
    border: 1px solid $color-principal;
    border-radius: 50%;

    path {
      fill: $color-principal;
    }
  }

  &--color-secondary-6 {
    fill: $color-secondary-6;

    path {
      fill: $color-secondary-6;
    }
  }

  &--color-secondary-3 {
    fill: $color-secondary-3;

    path {
      fill: $color-secondary-3;
    }
  }

  &--color-secondary-4 {
    fill: $color-secondary-4;

    path {
      fill: $color-secondary-4;
    }
  }

  &--color-secondary-1 {
    fill: $color-secondary-1;

    path {
      fill: $color-secondary-1;
    }
  }
}
