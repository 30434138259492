@import "../../../styles/shared.scss";

.rsw-editor {
  min-height: 200px !important;
  margin-bottom: 8px !important;

  ul li { list-style: disc !important;}
}

.searcher {
  &__filter {
    display: flex;
    align-items: center;
    margin-top: 6rem;
    margin-bottom: 2rem;
    overflow: hidden;

    @media (max-width: $breakpoint-tablet) {
      margin-top: 2rem;
      margin-bottom: 1rem;
    }
  }

  &__filter-items {
    flex: 1;
    display: flex;

    @media (max-width: $breakpoint-tablet) {
      overflow-x: auto;
    }
  }

  &__filter-item {
    display: flex;
    align-items: center;
    border-radius: 3rem;
    white-space: nowrap;

    &.checked {
      background-color: $color-secondary-6;
    }

    input[type=radio] {
      margin-right: .5rem !important;
      outline: none;
      appearance: none;
      width: 1rem;
      height: 1rem;
      border-radius: 1rem;
      border: 1px solid $color-secondary-3;
      position: relative;
      padding: 0;

      &:after {
        content: '';
        display: block;
        width: .62rem;
        height: .62rem;
        border-radius: 1rem;
        background-color: $color-principal;
        position: absolute;
        top: 50%;
        left: 50%;
        transform: translate(-50%, -50%) scale(1.1);
        opacity: 0;
        transition: all .2s ease-in;
      }

      &:checked {
        border-color: $color-principal;
        &:after {
          opacity: 1;
          transform: translate(-50%, -50%) scale(1);
        }
      }
    }
  }
}