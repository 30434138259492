@import "../src/styles/shared.scss";

* {
  -webkit-box-sizing: border-box; /* Safari/Chrome, other WebKit */
  -moz-box-sizing: border-box;    /* Firefox, other Gecko */
  box-sizing: border-box;         /* Opera/IE 8+ */
}

body {
  
}

html, body, #root, .App{
  width: 100%;
  height: 100%;
}

.App {
  display: flex;
  flex-direction: row;
}

#menu {
  display: flex;
  flex-direction: column;
  width: 200px;
  height: 100%;
  background-color: $color-gray-background;
  padding: 24px;
  position: fixed;
  left: 0;
  top: 0;
  z-index: 999;
}

#app-content {
  display: flex;
  flex-direction: column;
  flex-grow: 1;
  height: 100%;
  margin-left: 200px;
  padding: 24px 48px;
}

.success{ 
  font-size: 14px;
  line-height: 1.2em;
  color: green; 
}

.error{ 
  font-size: 14px;
  line-height: 1.2em;
  color: red; 
}

.pointer{
  cursor: pointer !important;
}

