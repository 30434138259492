$color-green: #e3ff00;
$color-black: #000000;
$color-white: #ffffff;

$color-principal: #f05a24;
$color-principal-05: rgba(240, 90, 36, 0.05);
$color-principal-10: rgba(240, 90, 36, 0.1);

//secondary
$color-secondary-1:#323232;
$color-secondary-2: #656565;
$color-secondary-3: #979797;
$color-secondary-4: #CACACA;
$color-secondary-5:#EFEFEF;
$color-secondary-6: #F7F7F7;
$color-secondary-7:#ffff;

//tertiary
$color-tertiary-1: #4C4C4C;
$color-tertiary-2:#7E7E7E;
$color-tertiary-4: #B0B0B0;
$color-tertiary-5: #F5F5F5;
$color-tertiary-6: #00000034;
$color-tertiary-7: #00000153;
$color-tertiary-8: #4D4D4D;

$color-gray-background: #f9f9f9;
$color-border-input: #e2e2e2;
