@import "../../styles/shared.scss";

input {
  width: 100%;
  height: 3.5rem;
  font-size: 14px !important;
  font-family: $inter-tight;
  border: 1px solid $color-border-input;
  border-radius: 32px;
  color: $color-secondary-2;
  outline: none;
  padding: 0 24px;
  margin: 0.5em 0 !important;

  &::placeholder {
    color: #c1c1c1;
  }
}