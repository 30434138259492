@font-face {
  font-family: "novela";
  src: url("../assets/fonts/Novela-Regular.otf") format("truetype");
}
@font-face {
  font-family: "inter-tight";
  src: url("../assets/fonts/InterTight-VariableFont_wght.ttf") format("truetype");
}

$novela: 'novela', sans-serif;
$inter-tight: 'inter-tight', sans-serif;

h1{
  font-family: $novela;
  font-size: 1.8em !important;
  font-weight: 800 !important;
  text-transform: uppercase;
  font-style: italic;
}