@import "../../styles/shared.scss";

table{
  border-radius: 8px !important;
}

.table-responsive{ 
  min-width: 1030px;
}

.table-header{
  display:flex;
  flex-direction: row;
  justify-content: space-between;
  align-items: center;
}

th{ 
  position: sticky;
  vertical-align: middle;
  border: 1px solid $color-white !important;
  background-color: $color-secondary-5 !important;

  &:first-of-type{
    border-top-left-radius: 10px;
    border-bottom-left-radius: 10px;
  }

  &:last-of-type{
    border-top-right-radius: 10px;
    border-bottom-right-radius: 10px;
  }
}

td{
  font-size: 12px;
}

.header_text{
  font-size: 14px;
  font-weight: 700;
  display: flex;
  align-items: center;
  justify-content: space-between;
}

.sort_icons{
  display: inline-flex;
  flex-direction: column;
}